<template>
  <div class="container my-5 text-center text-white">
    <h1>Layanan Kami</h1>
    <h4>Berbagai solusi IT untuk mendukung transformasi digital bisnis Anda.</h4>
    <div class="row">
      <div class="col-md-4">
        <div class="card card-bg text-white">
          <div class="card-body">
            <h5 class="card-title">Pengembangan Company Website</h5>
            <p class="card-text">Website yang Menarik, Bisnis yang Berkembang!</p>
            <p class="card-text">Buat kesan pertama yang tak terlupakan dengan website profesional kami. Didesain khusus untuk meningkatkan visibilitas dan kredibilitas bisnis Anda, siap menarik perhatian pelanggan baru. Mulailah perjalanan digital Anda hari ini!</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-bg text-white">
          <div class="card-body">
            <h5 class="card-title">Pengembangan Aplikasi<br>Web dan Mobile</h5>
            <p class="card-text">Aplikasi Inovatif untuk Semua Platform!</p>
            <p class="card-text">Raih pelanggan Anda di mana saja dengan aplikasi web dan mobile yang user-friendly. Dari konsep hingga peluncuran, kami hadir untuk mengubah ide Anda menjadi aplikasi yang berfungsi dengan baik dan menarik. Mari ciptakan pengalaman pengguna yang luar biasa bersama!</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-bg text-white">
          <div class="card-body">
            <h5 class="card-title">Pengembangan Sistem ERP</h5>
            <p class="card-text">Optimalkan Operasional Bisnis Anda!</p>
            <p class="card-text">Sistem ERP kami dirancang untuk menyederhanakan proses dan meningkatkan efisiensi. Integrasikan semua fungsi bisnis Anda dalam satu platform yang mudah diakses dan dikelola. Transformasi cara kerja Anda dan capai tujuan dengan lebih cepat!</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <div class="card card-bg text-white">
          <div class="card-body">
            <h5 class="card-title">Pengembangan Sistem CRM</h5>
            <p class="card-text">Hubungkan dengan Pelanggan Anda Lebih Baik!</p>
            <p class="card-text">Kembangkan hubungan yang lebih kuat dengan pelanggan melalui sistem CRM kami. Dapatkan wawasan mendalam tentang perilaku pelanggan dan tingkatkan pengalaman mereka. Tingkatkan loyalitas dan penjualan dengan solusi yang disesuaikan untuk kebutuhan Anda!</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-bg text-white">
          <div class="card-body">
            <h5 class="card-title">Penyedia Layanan Notifikasi WhatsApp Business Platform dari Meta</h5>
            <p class="card-text">Komunikasi yang Lebih Mudah dan Efisien!</p>
            <p class="card-text">Raih pelanggan Anda dengan notifikasi WhatsApp yang tepat waktu dan relevan. Layanan kami memungkinkan Anda berkomunikasi secara langsung dengan audiens Anda, meningkatkan keterlibatan dan kepuasan pelanggan. Koneksikan bisnis Anda dengan cara yang lebih personal!</p>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-bg text-white">
          <div class="card-body">
            <h5 class="card-title">Cloud & Domain Provider</h5>
            <p class="card-text">Solusi Cloud dan Domain untuk Masa Depan Anda!</p>
            <p class="card-text">Dapatkan kehadiran online yang kuat dengan layanan cloud dan domain kami. Fleksibel, aman, dan dapat diandalkan, kami membantu Anda mengelola sumber daya digital Anda dengan mudah. Mulai perjalanan digital Anda sekarang dengan solusi kami yang terintegrasi!</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  export default {
    name: 'ServicesPage',
  }
</script>
  
<style scoped lang="scss">
  h1 {
    margin-bottom: 30px;
  }
  .card {
    margin: 10px 0;
  }
  .card-bg {
    background-color: $primary-color;
  }
</style>
  