<template>
  <div class="container text-white my-5 text-center">
    <h1>Tentang Kami</h1>
    <div class="container-child">
      <h3>Selamat datang di Miraverse.ID — partner terpercaya Anda dalam dunia digital. Kami adalah tim profesional yang berkomitmen untuk menghadirkan solusi teknologi yang inovatif dan efektif, membantu bisnis Anda mencapai potensi terbaiknya.</h3>

      <h3>Visi Kami</h3>
      <p>Visi kami adalah memudahkan bisnis dalam bertransformasi ke era digital. Kami percaya bahwa setiap perusahaan, besar atau kecil, berhak mendapatkan alat dan teknologi yang tepat untuk berkembang dan bersaing di pasar global.</p>

      <h3>Mengapa Memilih Kami?</h3>
      <p>
        Kami memahami bahwa setiap bisnis memiliki tantangan unik. Oleh karena itu, kami menawarkan solusi yang disesuaikan untuk memenuhi kebutuhan spesifik Anda. Dengan tim yang berpengalaman dan layanan pelanggan yang responsif, kami berkomitmen untuk mendukung Anda di setiap langkah perjalanan digital Anda.
      </p>

      <h3>Bergabunglah Bersama Kami!</h3>
      <p>
        Mari wujudkan visi digital Anda bersama Miraverse.ID. Hubungi kami hari ini untuk mendiskusikan bagaimana kami dapat membantu bisnis Anda tumbuh dan sukses dalam dunia digital yang terus berkembang!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
}
</script>

<style scoped lang="scss">
.container-child {
  padding: 14px;
  background-color: $card-color;
  border-radius: 16px;
}
h1 {
  margin-bottom: 30px;
}
</style>
