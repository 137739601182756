<template>
  <div class="home" style="text-align: center; color: white;">
    <div class="jumbotron jumbotron-fluid">
      <div class="container">
        <h1 class="display-4">Pusat Solusi IT untuk Bisnis Anda</h1>
        <p class="lead">Kami menyediakan solusi digital inovatif untuk meningkatkan efisiensi dan kinerja bisnis Anda.</p>
        <router-link to="/services" class="btn btn-primary btn-lg">Lihat Layanan Kami</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage'
}
</script>

<style scoped>
.jumbotron {
  color: #fff;
}

.btn-primary {
  color: #fff;
}
</style>
