<template>
  <div class="container my-5 text-white">
    <h1 class="text-center">Kontak Kami</h1>
    <div class="row">
      <div class="col-md-3">
        <h4>Alamat</h4>
        <b><p class="mb-0">PT. MIRA BISA BERTEKNOLOGI</p></b>
        <p class="mb-0">Citra Maja Raya</p>
        <p class="mb-0">Cluster Vimala M16/01</p>
        <p class="mb-0">Maja Baru</p>
        <p>Lebak Banten 42382</p>
        
        <h4>No. Telepon</h4>
        <p>+62 8114938580</p>
        
        <h4>Email</h4>
        <p>info@miraverse.id</p>
        
        <h4>Sosial Media</h4>
        <div class="social-links">
          <a class="btn btn-outline-light btn-social" href="https://www.twitter.com"><i class="fa fa-twitter"></i></a>
          <a class="btn btn-outline-light btn-social" href="https://www.facebook.com"><i class="fa fa-facebook-f"></i></a>
          <a class="btn btn-outline-light btn-social" href="https://www.youtube.com"><i class="fa fa-youtube"></i></a>
          <a class="btn btn-outline-light btn-social" href="https://www.instagram.com"><i class="fa fa-instagram"></i></a>
          <a class="btn btn-outline-light btn-social" href="https://www.linkedin.com"><i class="fa fa-linkedin"></i></a>
        </div>
      </div>
      <div class="col-md-5">
        <div id="map"></div>
      </div>
      <div class="col-md-4">
        <form>
          <div class="form-group">
            <label for="name">Nama</label>
            <input type="text" class="form-control" id="name" placeholder="Nama Anda">
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" placeholder="Email Anda">
          </div>
          <div class="form-group">
            <label for="message">Pesan</label>
            <textarea class="form-control" id="message" rows="3" placeholder="Pesan Anda"></textarea>
          </div>
          <br>
          <button type="submit" class="btn btn-primary">Kirim</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import markerIcon from "@/../node_modules/leaflet/dist/images/marker-icon.png";

export default {
  name: 'ContactPage',
  mounted() {
    const map = L.map('map').setView([-6.350932470831206, 106.39100776837007], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors'
    }).addTo(map);

    // Tambahkan marker lokasi
    L.Marker.prototype.setIcon(L.icon({
      iconUrl: markerIcon,
      iconAnchor: [10, 0],
      popupAnchor: [2, 0]
    }))
    L.marker([-6.350932470831206, 106.39100776837007]).addTo(map)
      .bindPopup('Lokasi Perusahaan Kami')
      .openPopup();
  }
}
</script>

<style scoped lang="scss">
#map {
  height: 400px;
  border-radius: 18px;
}
.btn-social {
  border: 0;
  border-radius: 50%;
  color: $text-color;
}
.btn-social:hover {
  color: $primary-color;
}
.social-links {
  margin-left: 0;
}
</style>
