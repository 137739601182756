<template>
  <div class="py-5 text-white">
    <div class="container">
      <h2 class="text-center">Projects</h2>
      <div class="row">
        <div class="col-md-4" v-for="project in projects" :key="project.id">
          <div class="card mb-4">
            <img :src="project.image" class="card-img-top" alt="Project image">
            <div class="card-body">
              <h5 class="card-title">{{ project.name }}</h5>
              <p class="card-text">{{ project.description }}</p>
              <!-- <a :href="project.link" class="btn btn-primary" style="color: white" target="_blank">View Project</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProjectsPage',
  data() {
    return {
      projects: [
        { id: 1, name: 'Project One', description: 'Description for project one', link: '#', image: 'https://via.placeholder.com/400x300' },
        { id: 2, name: 'Project Two', description: 'Description for project two', link: '#', image: 'https://via.placeholder.com/400x300' },
        { id: 3, name: 'Project Three', description: 'Description for project three', link: '#', image: 'https://via.placeholder.com/400x300' },
      ]
    }
  }
}
</script>
