<template>
  <nav class="navbar navbar-expand-lg" :class="{'navbar-transparent': isTransparent, 'navbar-solid': !isTransparent}" ref="navbar">
    <a class="navbar-brand" href="#"><img :src="logo" style="height: 50px;" /> Mira Bisa Berteknologi</a>
    <button
      id="btnToggleMenu"
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
      @click="onToggled"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item"><router-link to="/" class="nav-link">Home</router-link></li>
        <li class="nav-item"><router-link to="/about" class="nav-link">Tentang Kami</router-link></li>
        <li class="nav-item"><router-link to="/services" class="nav-link">Layanan</router-link></li>
        <li class="nav-item"><router-link to="/projects" class="nav-link">Proyek Kami</router-link></li>
        <li class="nav-item"><router-link to="/contact" class="nav-link">Kontak</router-link></li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'HeaderComponent',
  data() {
    return {
      isTransparent: true, // Menentukan apakah navbar transparan atau tidak
      isMenuOpen: false,
      logo: require('@/assets/images/logo-mira.png'),
    };
  },
  mounted() {
    // Tambahkan event listener saat komponen di-mount
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    // Bersihkan event listener saat komponen dihancurkan
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.isMenuOpen) {
        this.isTransparent = false
      } else {
        // Deteksi scroll dan ubah status isTransparent
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 10) {
          this.isTransparent = false;
        } else {
          this.isTransparent = true;
        }
      }
    },
    onToggled() {
      const navLinks = document.querySelectorAll('.nav-item')
      this.isMenuOpen = !this.isMenuOpen
      if (this.isMenuOpen) {
        this.isTransparent = false
      } else {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop <= 10) {
          this.isTransparent = true
        }
      }

      // Tambahkan handler untuk close navbar saat klik menu
      navLinks.forEach((x) => {
        if (this.isMenuOpen) {
          // if open menu then add listener
          x.addEventListener('click', () => { 
            document.getElementById('btnToggleMenu').click() 
          })
        } else {
          // remove listener if menu closed
          x.replaceWith(x.cloneNode(true))
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.navbar {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  z-index: 10;
}

.navbar-transparent {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: $text-color;
}

.navbar-solid {
  background-color: $navbar-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  color: $text-color;
}

.nav-link {
  color: $text-color !important;
}

.navbar-brand {
  color: $text-color !important;
}
</style>
