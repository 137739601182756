import Vue from 'vue'
import App from './App'
import router from './router'
import vuetify from './plugins/vuetify'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import 'leaflet/dist/leaflet.css'
import 'font-awesome/css/font-awesome.css'

import '@/assets/styles.scss'

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  vuetify,
  render: h => h(App)
})
