<template>
  <v-app class="container-fluid p-0" :style="{backgroundImage: 'url(' + bgImage + ')', backgroundSize: 'cover', height: 'auto'}">
    <Header></Header>
    <div class="content-wrapper">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      bgImage: require('@/assets/images/bg-image.webp') // Background untuk halaman beranda
    }
  },
}
</script>

<!-- CSS Transisi -->
<style>
.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  padding-top: 60px; /* To avoid overlapping with navbar */
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
