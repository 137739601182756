<template>
  <footer class="footer mt-auto py-3 bg-dark text-white text-center">
    <div class="container">
      &copy; 2024 Miraverse.id. All rights reserved.
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
}
</script>
